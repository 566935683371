
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      components: {Header, Footer, PaymentModal},
      name: 'PaymentDemoPage',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
      mounted() {
        /* @ts-ignore */
        document.getElementById('test-scroll').addEventListener('scroll', this.handleScroll);
      },
      data() {
        return {
          isClicked: false,
          key: 0,
          scrollPosition: 0,
          scrollNo: 0,
          showModal: false,
        };
      },
      methods: {
        spanClicked(key: number) {
          this.key = key;
          this.isClicked = !this.isClicked;
        },
        isActive(key: number) {
          return this.isClicked && this.key === key;
        },
        handleScroll() {
          this.scrollPosition = window.scrollY;
        },
        changeScrollBarPos(pos: number, scrollNo: number) {
          this.scrollNo = scrollNo;
          /* @ts-ignore */
          document.getElementById('test-scroll').scrollTo({top: pos, behavior: 'smooth'});
        },
        openmodal() {
          if (this.showModal) {
            this.showModal = false;
            setTimeout(() => (this.showModal = true), 10);
          } else if (!this.showModal) {
            this.showModal = true;
          }
        },
      },
    }
);
