
import {useI18n} from 'vue-i18n';
import {computed, defineComponent, reactive} from 'vue';
import {ref} from 'vue';
import {onClickOutside} from '@vueuse/core';

export default defineComponent(
    {
      name: 'PaymentModal',
      setup() {
        const {t, locale} = useI18n();
        const el = ref();

        const state = reactive({
                                 Modal: 'modalsScroll open',
                                 String: computed(() => 'modalsScroll open'),
                               });

        function close() {
          state.Modal = 'modalsScroll';
        }

        onClickOutside(el, close);

        return {t, locale, el, state};
      },
      data() {
        return {};
      },
    }
);
